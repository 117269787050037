import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { FaGamepad, FaUsers, FaStar } from 'react-icons/fa';

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(135deg, #0A192F 0%, #20314F 50%, #253B63 100%);
  padding: 1rem;
  color: white;

  @media (min-width: 768px) {
    padding: 4rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const HeaderSection = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const MainContent = styled.div`
  display: grid;
  gap: 2rem;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
    align-items: center;
  }
`;

const TextContent = styled.div`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 15px;
  text-align: left;

  @media (min-width: 768px) {
    padding: 2.5rem;
    border-radius: 25px;
  }

  p {
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    line-height: 1.8;
    color: rgba(255, 255, 255, 0.9);
  }
`;

const StatsGrid = styled.div`
  display: grid;
  gap: 1.5rem;
  
  @media (min-width: 768px) {
    gap: 2rem;
  }
`;

const StatCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media (min-width: 768px) {
    padding: 2rem;
    border-radius: 20px;
  }
`;

const IconWrapper = styled.div`
  font-size: 2rem;
  color: #63B3ED;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: rgba(99, 179, 237, 0.1);
  border-radius: 15px;
`;

const StatContent = styled.div`
  flex: 1;
`;

const StatTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: white;
`;

const StatDescription = styled.p`
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
`;

const Highlight = styled.span`
  color: #63B3ED;
  font-weight: 600;
`;

const About = () => {
  return (
    <Container>
      <ContentWrapper>
        <HeaderSection>
          <Title>About Peekjoy Interactive</Title>
        </HeaderSection>

        <MainContent>
          <TextContent>
            <p>
              Founded in late 2017, <Highlight>Peekjoy Interactive</Highlight> has emerged as a passionate and dedicated game development studio. Our journey began with a simple mission: to create engaging and entertaining mobile games that bring joy to players worldwide.
            </p>
            <p>
              As a small but dynamic team of developers, we work tirelessly to craft exceptional gaming experiences. Our commitment to quality drives us to continuously improve and innovate, ensuring each game we release meets the highest standards of entertainment and technical excellence.
            </p>
            <p>
              From action-packed adventures to casual entertainment, our diverse portfolio reflects our passion for gaming and our dedication to delivering memorable experiences to our growing community of players.
            </p>
          </TextContent>

          <StatsGrid>
            <StatCard
              whileHover={{ y: -5 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <IconWrapper>
                <FaGamepad />
              </IconWrapper>
              <StatContent>
                <StatTitle>Game Development</StatTitle>
                <StatDescription>Creating engaging mobile games with innovative gameplay mechanics</StatDescription>
              </StatContent>
            </StatCard>

            <StatCard
              whileHover={{ y: -5 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <IconWrapper>
                <FaUsers />
              </IconWrapper>
              <StatContent>
                <StatTitle>Player Community</StatTitle>
                <StatDescription>Building a global community of happy and engaged players</StatDescription>
              </StatContent>
            </StatCard>

            <StatCard
              whileHover={{ y: -5 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <IconWrapper>
                <FaStar />
              </IconWrapper>
              <StatContent>
                <StatTitle>Quality Focus</StatTitle>
                <StatDescription>Committed to delivering high-quality gaming experiences</StatDescription>
              </StatContent>
            </StatCard>
          </StatsGrid>
        </MainContent>
      </ContentWrapper>
    </Container>
  );
};

export default About; 