import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import gamesData from '../games.json';

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(135deg, #F0F8FF 0%, #E1F5FE 50%, #B3E5FC 100%);
  padding: 2rem 1rem;
  color: #1E3A8A;

  @media (min-width: 768px) {
    padding: 4rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
`;

const Title = styled(motion.h1)`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #1E3A8A;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 3.5rem;
  }
`;

const Description = styled(motion.p)`
  text-align: center;
  margin-bottom: 3rem;
  color: #2563EB;
  font-size: 1rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
  padding: 0 1rem;

  @media (min-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 4rem;
  }
`;

const GamesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  padding: 0.5rem;

  @media (min-width: 768px) {
    gap: 2.5rem;
    padding: 1rem 0;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #1E3A8A;
`;

const GameCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 8px 20px rgba(37, 99, 235, 0.1);

  @media (min-width: 768px) {
    padding: 2rem;
  }

  &:hover {
    transform: translateY(-10px);
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 15px 30px rgba(37, 99, 235, 0.2);
  }

  @media (hover: none) {
    &:active {
      transform: scale(0.98);
    }
  }
`;

const GameIcon = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 25px;
  margin-bottom: 1rem;
  box-shadow: 0 8px 16px rgba(37, 99, 235, 0.15);
  background: rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease;

  @media (min-width: 768px) {
    width: 150px;
    height: 150px;
    margin-bottom: 1.5rem;
  }
`;

const GameTitle = styled.h3`
  font-size: 1.25rem;
  margin: 0.5rem 0;
  color: #1E3A8A;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    margin: 0.8rem 0;
  }
`;

const GameDescription = styled.p`
  font-size: 0.9rem;
  color: #3B82F6;
  line-height: 1.6;
  padding: 0 0.5rem;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const processUrl = (name) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
};

const OurGames = () => {
  return (
    <Container>
      <ContentWrapper>
        <Title
          initial={{ y: -30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          Our Games Collection
        </Title>
        <Description
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          Discover our diverse portfolio of mobile games, each crafted with passion and optimized for the best gaming experience.
        </Description>
        <GamesGrid>
          {gamesData.slice(1).map((game, index) => (
            <StyledLink 
              key={index}
              to={`/games/${processUrl(game.name)}`}
            >
              <GameCard
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: Math.min(index * 0.1, 1) }}
              >
                <GameIcon 
                  src={game.icon} 
                  alt={game.name}
                  loading="lazy"
                  decoding="async"
                />
                <GameTitle>{game.name}</GameTitle>
                <GameDescription>{game["short description"]}</GameDescription>
              </GameCard>
            </StyledLink>
          ))}
        </GamesGrid>
      </ContentWrapper>
    </Container>
  );
};

export default OurGames;