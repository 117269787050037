import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const Nav = styled.nav`
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 1rem;
  position: relative;
  z-index: 1000;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

const NavContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled(Link)`
  font-family: ${props => props.theme.fonts.heading};
  font-size: 1.25rem;
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  text-decoration: none;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const NavLinks = styled.div`
  display: none;
  gap: 2rem;

  @media (min-width: 768px) {
    display: flex;
  }
`;

const MobileMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: ${props => props.theme.colors.primary};
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;

  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  transform: translateX(${props => props.isOpen ? '0' : '100%'});
  transition: transform 0.3s ease-in-out;
  z-index: 999;
`;

const MobileNavLink = styled(Link)`
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  font-weight: 500;
  font-size: 1.25rem;
  padding: 1rem;
  transition: color 0.2s ease;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

const NavLink = styled(Link)`
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: ${props => props.theme.colors.primary};
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
`;

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Nav>
      <NavContainer>
        <Logo to="/" onClick={closeMenu}>Peekjoy Interactive</Logo>
        <NavLinks>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/games">Our Games</NavLink>
          <NavLink to="/about">About Us</NavLink>
          <NavLink to="/privacy-policy">Privacy Policy</NavLink>
        </NavLinks>
        <MobileMenuButton onClick={toggleMenu}>
          <FaBars />
        </MobileMenuButton>
      </NavContainer>

      <MobileMenu isOpen={isMenuOpen}>
        <CloseButton onClick={toggleMenu}>
          <FaTimes />
        </CloseButton>
        <MobileNavLink to="/" onClick={closeMenu}>Home</MobileNavLink>
        <MobileNavLink to="/games" onClick={closeMenu}>Our Games</MobileNavLink>
        <MobileNavLink to="/about" onClick={closeMenu}>About Us</MobileNavLink>
        <MobileNavLink to="/privacy-policy" onClick={closeMenu}>Privacy Policy</MobileNavLink>
      </MobileMenu>
    </Nav>
  );
};

export default Navbar; 