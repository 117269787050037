import styled from '@emotion/styled';

const FooterContainer = styled.footer`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 1.5rem;
  margin-top: auto;

  @media (min-width: 768px) {
    padding: 2rem;
  }
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.6;

  @media (min-width: 768px) {
    font-size: 1rem;
  }

  p {
    margin: 0;
    padding: 0 1rem;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <p>© {new Date().getFullYear()} Peekjoy Interactive. All rights reserved.</p>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer; 