import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useParams, Navigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaGooglePlay } from 'react-icons/fa';
import gamesData from '../games.json';

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(135deg, #0A192F 0%, #20314F 50%, #253B63 100%);
  padding: 1rem;
  color: white;

  @media (min-width: 768px) {
    padding: 4rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const GameHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 15px;
  
  @media (min-width: 768px) {
    flex-direction: row;
    text-align: left;
    gap: 3rem;
    margin-bottom: 4rem;
    padding: 3rem;
    border-radius: 25px;
  }
`;

const GameIcon = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 25px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    width: 180px;
    height: 180px;
    border-radius: 35px;
  }
`;

const GameInfo = styled.div`
  flex: 1;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 0.8rem;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
`;

const ShortDescription = styled.p`
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  line-height: 1.6;

  @media (min-width: 768px) {
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }
`;

const ContentSection = styled.div`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 15px;
  margin-top: 1.5rem;

  @media (min-width: 768px) {
    padding: 3rem;
    border-radius: 25px;
    margin-top: 2rem;
  }
`;

const Description = styled.div`
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
  font-size: 1rem;
  margin: 1rem 0;

  p {
    margin-bottom: 1.2rem;
    text-align: left;
    padding: 0 0.5rem;
  }

  h2 {
    color: white;
    font-size: 1.4rem;
    margin: 1.5rem 0 1rem;
    font-family: 'Poppins', sans-serif;
    padding: 0 0.5rem;
  }

  @media (min-width: 768px) {
    line-height: 1.8;
    font-size: 1.1rem;
    margin: 2rem 0;

    p {
      margin-bottom: 1.5rem;
      padding: 0;
    }

    h2 {
      font-size: 1.8rem;
      margin: 2rem 0 1rem;
      padding: 0;
    }
  }
`;

const StoreButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  background: linear-gradient(135deg, #63B3ED, #4299E1);
  color: white;
  width: 100%;
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    width: auto;
    padding: 1rem 2.5rem;
    font-size: 1.2rem;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(-1px);
  }
`;

const processUrl = (name) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
};

const GameDetails = () => {
  const { gameName } = useParams();
  const game = gamesData.find(g => processUrl(g.name) === gameName);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [gameName]);

  if (!game) {
    return <Navigate to="/games" />;
  }

  const formatDescription = (description) => {
    // Split by common sentence endings followed by space
    const sentences = description.split(/(?<=[.!?])\s+/);
    
    // Group sentences into paragraphs (roughly 2-3 sentences per paragraph)
    const paragraphs = [];
    let currentParagraph = [];
    
    sentences.forEach((sentence, index) => {
      currentParagraph.push(sentence);
      
      // Create new paragraph after every 2-3 sentences or if sentence contains specific keywords
      if (
        currentParagraph.length >= 3 ||
        sentence.includes('!') ||
        sentence.includes('Features:') ||
        sentence.includes('Game Features:') ||
        sentence.toLowerCase().includes('download') ||
        index === sentences.length - 1
      ) {
        paragraphs.push(currentParagraph.join(' '));
        currentParagraph = [];
      }
    });

    // Add any remaining sentences
    if (currentParagraph.length > 0) {
      paragraphs.push(currentParagraph.join(' '));
    }

    // Format the text with proper sections
    return (
      <>
        <h2>About the Game</h2>
        {paragraphs.map((paragraph, index) => (
          <p key={index}>
            {paragraph.trim()}
          </p>
        ))}
      </>
    );
  };

  return (
    <Container>
      <ContentWrapper>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <GameHeader>
            <GameIcon src={game.icon} alt={game.name} />
            <GameInfo>
              <Title>{game.name}</Title>
              <ShortDescription>{game["short description"]}</ShortDescription>
              <StoreButton href={game.appstorelink} target="_blank" rel="noopener noreferrer">
                <FaGooglePlay />
                Download Now
              </StoreButton>
            </GameInfo>
          </GameHeader>

          <ContentSection>
            <Description>
              {formatDescription(game.description)}
            </Description>
          </ContentSection>
        </motion.div>
      </ContentWrapper>
    </Container>
  );
};

export default GameDetails;