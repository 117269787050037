import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { FaStar, FaGooglePlay } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import gamesData from '../games.json';

const HeroSection = styled.section`
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    135deg,
    #0A192F 0%,
    #20314F 50%,
    #253B63 100%
  );
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 50% 50%, rgba(99, 179, 237, 0.1) 0%, rgba(99, 179, 237, 0) 70%);
`;

const StudioName = styled(motion.span)`
  background: linear-gradient(to right, #63B3ED, #4299E1);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 800;
`;

const HeroContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  position: relative;
  z-index: 1;

  @media (min-width: 768px) {
    padding: 6rem 2rem;
  }
`;

const HeroGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (min-width: 968px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: center;
  }
`;

const HeroText = styled.div`
  text-align: center;

  @media (min-width: 968px) {
    text-align: left;
  }
`;

const Title = styled(motion.h1)`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: white;
  line-height: 1.2;

  @media (min-width: 768px) {
    font-size: 4.5rem;
    margin-bottom: 2rem;
  }
`;

const Description = styled(motion.p)`
  font-size: 1.1rem;
  margin-bottom: 2rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  font-family: 'Inter', sans-serif;

  @media (min-width: 768px) {
    font-size: 1.4rem;
    margin-bottom: 2.5rem;
    line-height: 1.8;
  }
`;

const FeaturedGames = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  max-width: 600px;
  margin: 0 auto;

  @media (min-width: 768px) {
    gap: 2rem;
  }
`;

const FeaturedGame = styled(Link)`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 1rem;
  text-decoration: none;
  color: white;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    padding: 2rem;
    border-radius: 20px;
  }

  &:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.15);
  }
`;

const GameIcon = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 15px;
  margin-bottom: 0.5rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    width: 120px;
    height: 120px;
    margin-bottom: 1rem;
  }
`;

const GameTitle = styled.h3`
  font-size: 0.9rem;
  color: white;
  text-align: center;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const StatsSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin: 2rem 0;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  backdrop-filter: blur(10px);

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin: 4rem 0;
    padding: 2rem;
    border-radius: 20px;
  }
`;

const StatItem = styled(motion.div)`
  text-align: center;
`;

const StatNumber = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #63B3ED;
  margin-bottom: 0.5rem;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const StatLabel = styled.div`
  font-size: 1rem;
  color: white;
  opacity: 0.9;

  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`;

const CTAButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  background: linear-gradient(135deg, #63B3ED, #4299E1);
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(99, 179, 237, 0.3);

  @media (min-width: 768px) {
    padding: 1rem 2rem;
    font-size: 1.1rem;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 15px 30px rgba(99, 179, 237, 0.4);
  }
`;

const FloatingStars = styled(motion.div)`
  position: absolute;
  color: rgba(255, 255, 255, 0.1);
  font-size: ${props => props.size || '1rem'};
  top: ${props => props.top};
  left: ${props => props.left};
  pointer-events: none;
`;

const Home = () => {
  const featuredGames = gamesData.slice(1, 5); // Get first 4 games

  return (
    <HeroSection>
      <HeroOverlay />
      {[...Array(15)].map((_, i) => (
        <FloatingStars
          key={i}
          size={`${Math.random() * 2 + 1}rem`}
          top={`${Math.random() * 100}%`}
          left={`${Math.random() * 100}%`}
          initial={{ opacity: 0.3 }}
          animate={{ 
            opacity: [0.3, 0.8, 0.3],
            y: [0, -20, 0]
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            delay: i * 0.2
          }}
        >
          <FaStar />
        </FloatingStars>
      ))}
      <HeroContent>
        <HeroGrid>
          <HeroText>
            <Title
              initial={{ y: -30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
            >
              Crafting Joy at <StudioName>Peekjoy</StudioName>
            </Title>
            <Description
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              Since 2017, we've been creating captivating mobile games that bring entertainment to millions. Our passion for gaming drives us to deliver exceptional experiences that players love.
            </Description>
            <CTAButton to="/games">
              <FaGooglePlay />
              Explore Our Games
            </CTAButton>
          </HeroText>

          <FeaturedGames
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            {featuredGames.map((game, index) => (
              <FeaturedGame 
                key={index}
                to={`/games/${processUrl(game.name)}`}
              >
                <GameIcon src={game.icon} alt={game.name} />
                <GameTitle>{game.name}</GameTitle>
              </FeaturedGame>
            ))}
          </FeaturedGames>
        </HeroGrid>

        <StatsSection>
          {[
            { number: `+${gamesData.length - 1}`, label: "Games Published" },
            { number: "1M+", label: "Happy Players" },
            { number: "4.5", label: "Average Rating" }
          ].map((stat, index) => (
            <StatItem
              key={index}
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.8 + index * 0.1 }}
            >
              <StatNumber>{stat.number}</StatNumber>
              <StatLabel>{stat.label}</StatLabel>
            </StatItem>
          ))}
        </StatsSection>
      </HeroContent>
    </HeroSection>
  );
};

const processUrl = (name) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
};

export default Home;