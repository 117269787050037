import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(135deg, #0A192F 0%, #20314F 50%, #253B63 100%);
  padding: 1rem;
  color: white;

  @media (min-width: 768px) {
    padding: 4rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 15px;

  @media (min-width: 768px) {
    padding: 3rem;
    border-radius: 25px;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
`;

const Content = styled.div`
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
  font-size: 1rem;

  p {
    margin-bottom: 1.5rem;
    text-align: left;
    padding: 0 0.5rem;
  }

  h2 {
    color: white;
    font-size: 1.4rem;
    margin: 2rem 0 1rem;
    font-family: 'Poppins', sans-serif;
    padding: 0 0.5rem;
  }

  @media (min-width: 768px) {
    line-height: 1.8;
    font-size: 1.1rem;

    h2 {
      font-size: 1.8rem;
    }

    p {
      padding: 0;
    }
  }
`;

const Privacy = () => {
  return (
    <Container>
      <ContentWrapper
        as={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Title>Privacy Policy</Title>
        <Content>
          <p>Last updated: {new Date().toLocaleDateString()}</p>
          
          <h2>Introduction</h2>
          <p>
            At PeekJoy Interactive, we love making engaging games available on mobile devices. We respect the privacy concerns of all users of our games, apps, websites, and related services. By using our games and applications, you agree to observe and be bound by this Privacy Policy and consent to the processing, collection, and use of your information and data under its terms.
          </p>

          <h2>What data do we collect?</h2>
          <p>
            Throughout our games, websites, and related services, we may collect ‘non-personal data’. This data does not identify you directly but may be aggregated with other data to help us improve our games and analyze usage statistics and metrics to provide you with more stable and optimized content. 
          </p>

          <h2>Our Policy Regarding Children</h2>
          <p>
            PeekJoy does not knowingly collect or solicit personal information from any of our users. Please note: You can find published age recommendations on Google Play and Amazon App Store. However, these age or maturity classifications refer only to the content in our games (similar to movie ratings G, PG, or PG-13 in the U.S., but related to gameplay, animations, etc.). For more on how these categories are defined, visit the support websites for Google and Amazon.
          </p>

          <h2>What is Personal Information and Why Do We Collect It?</h2>
          <p>
            All our games and apps do not collect any personal information; they use general/normal permissions. We may collect some usage metrics to check if our games and apps are stable and for crash analytics to provide fixes in the near future. We do not collect any personal data such as names, addresses, or similar information. PeekJoy will not attempt to obtain or record any of your information.
          </p>

          <h2>Disclosure of Personal Information</h2>
          <p>
            We will not sell, trade, rent, or disclose any of the usage and statistics data we collect for any reason or in any case, without your prior consent. Otherwise, this information will only be disclosed where required or allowable by law. We may share generic aggregated demographic information not linked to any personal identification information with trusted affiliates and advertisers for the purposes outlined above.
          </p>

          <h2>Interest-Based Advertising</h2>
          <p>
            When you use our apps, third parties may collect data about your mobile device and how you use our apps in order to serve ads on other apps or websites that are tailored to your interests, a practice known as “interest-based advertising.” Examples of data that may be collected can include Advertising ID, identifiers associated with your mobile device or applications on the device, how you use our apps, and so on.
          </p>

          <h2>Our Third Party Partners</h2>
          <p>
            Our third party partners, and their privacy policies are as follows:
          </p>
          <p>AdMob: https://www.google.com/intl/en/policies/privacy/</p>
          <p>Unity Ads: http://unity3d.com/legal/privacy-policy</p>
          <p>Game Analytics SDK: https://docs.fabric.io/android/fabric/data-privacy.html</p>
          <p>ironSource SDK: https://www.ironsrc.com/privacy-policy/</p>

          <h2>Policy Updates</h2>
          <p>
            This Policy may change from time to time. The most current version of our Privacy Policy can be found on our website. We will always keep this up to date so that you know what information we may collect from you in the future, how we may use it, and when we may disclose it.
          </p>

          <h2>Privacy Policy Contact Information</h2>
          <p>
            If you have any questions about our Privacy Policy, please feel free to contact us at contact@peekjoy.com (official new email).
          </p>
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default Privacy; 