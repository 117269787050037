export const theme = {
  colors: {
    primary: '#4A90E2',    // Trustworthy blue
    secondary: '#6B5B95',  // Soft purple
    accent: '#FF6B6B',     // Warm accent
    background: '#F8F9FA',
    text: '#2C3E50',
    white: '#FFFFFF',
  },
  fonts: {
    heading: "'Poppins', sans-serif",
    body: "'Inter', sans-serif",
  },
  breakpoints: {
    mobile: '320px',
    tablet: '768px',
    desktop: '1024px',
  }
}; 